import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/auth/AuthContext';

const PrivateRoute = ({ children, adminOnly = false }) => {
  const { isLoggedIn, userRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // Assurez-vous que userRole est défini avant de faire la redirection
    if (isLoggedIn === false && location.pathname !== '/login') {
      navigate('/login');
    } else if (adminOnly && userRole !== 'admin' && userRole !== null) {
      navigate('/'); // ou toute autre route appropriée
    }
  }, [isLoggedIn, userRole, location, navigate, adminOnly]);

  if (isLoggedIn && (adminOnly && userRole === null)) {
    // Attendre que userRole soit défini si l'utilisateur est connecté
    return null; // ou un spinner de chargement
  }

  return isLoggedIn && (!adminOnly || userRole === 'admin') ? children : <Navigate to="/login" />;
};

export default PrivateRoute;

import React, { useState, useEffect } from 'react';
import { Input, Button, Box, useToast, Radio, RadioGroup, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import styled from 'styled-components';

import { useGetRequest } from '../utils/hooks/useGetRequest';
import { isSiretValid } from '../utils/function/validate';
import { processDataJordan } from '../utils/function/trimData';

import UploadEverysConsole from './UploadEverysConsole';

const StyledBox = styled(Box)`
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

function SiretForm() {
  const [siret, setSiret] = useState('');
  const [siren, setSiren] = useState('');
  const [akuiteo, setAkuiteo] = useState('');
  const [triggerAkuiteo, setTriggerAkuiteo] = useState('');
  const [finalData, setFinalData] = useState(null);
  const [isDataProcessed, setIsDataProcessed] = useState(false);
  const [selectedRpps, setSelectedRpps] = useState(null);
  const [availableRpps, setAvailableRpps] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Utilisez votre hook personnalisé, les requêtes ne seront déclenchées que si les variables respectives ne sont pas vides
  const { data: dataInpi, isLoading: isLoadingInpi, error: errorInpi } = useGetRequest(siren ? `/api/inpi/siren/${siren}` : null);
  const { data: dataAkuiteo, isLoading: isLoadingAkuiteo, error: errorAkuiteo } = useGetRequest(triggerAkuiteo ? `/api/everys/client/${triggerAkuiteo}` : null);
  const { data: dataAnnuaire, isLoading: isLoadingAnnuaire, error: errorAnnuaire } = useGetRequest(dataAkuiteo && dataAkuiteo.FINESS !== "null" ? `/api/annuairesante/rpps/${dataAkuiteo.FINESS}` : null);

  const resetForm = () => {
    setSiret('');
    setSiren('');
    setAkuiteo('');
    setTriggerAkuiteo('');
    setFinalData(null);
    setIsDataProcessed(false);
    setSelectedRpps(null);
    setAvailableRpps([]);
  };

  useEffect(() => {
    if (dataInpi && dataAkuiteo && dataAnnuaire && selectedRpps) {
      const dataTraitee = processDataJordan(dataInpi, dataAkuiteo, dataAnnuaire, selectedRpps);
      setFinalData(dataTraitee);
      setIsDataProcessed(true);
    }
  }, [dataInpi, dataAkuiteo, dataAnnuaire, selectedRpps]);

  useEffect(() => {
    if (dataAkuiteo && dataAkuiteo.FINESS === "null") {
      toast({
        title: 'Erreur',
        description: "Le champ FINESS est 'null' dans les données Akuiteo.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      resetForm();  // Réinitialiser le formulaire lorsqu'une erreur est détectée
    }
  }, [dataAkuiteo, toast]);

  useEffect(() => {
    if (dataAnnuaire) {
      setAvailableRpps(dataAnnuaire['hydra:member']);
      onOpen(); // Ouvrir la modal lorsque les données sont disponibles
    }
  }, [dataAnnuaire, onOpen]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSiretValid(siret)) {
      const newSiren = siret.substring(0, 9);
      setSiren(newSiren); // Déclenche la requête pour INPI si le SIRET est valide
      setTriggerAkuiteo(akuiteo); // Maintenant, cela déclenche la requête pour Akuiteo seulement après la soumission
    } else {
      toast({
        title: 'Erreur',
        description: 'Numéro SIRET invalide.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDownload = async () => {
    if (finalData) {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL_BACKEND}/api/documents/generate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(finalData),
        });

        if (!response.ok) {
          throw new Error('Réponse réseau non ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Document.docx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Erreur lors de la génération du document :', error);
        toast({
          title: 'Erreur',
          description: "Erreur lors de la génération du document.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // Affichage conditionnel basé sur le chargement ou le traitement des données
  if (isLoadingAnnuaire) {
    return <StyledBox>Chargement du rpps</StyledBox>;
  }

  return (
    <>
      <div style={{ padding: '20px' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Mettre à jour EverysConsole :</h1>
        <UploadEverysConsole />
      </div>
      <StyledBox>
        {!isDataProcessed ? (
          <>
            <form onSubmit={handleSubmit}>
              <Input
                placeholder="Entrez le numéro SIRET"
                value={siret}
                onChange={(e) => setSiret(e.target.value)}
              />
              <Input
                mt={3}
                placeholder="Entrez le numéro Akuiteo"
                value={akuiteo}
                onChange={(e) => setAkuiteo(e.target.value)}
              />
              <Button type="submit" colorScheme="blue" mt={4} isDisabled={!isSiretValid(siret)}>
                Générer
              </Button>
            </form>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Choisissez un professionnel de santé</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {availableRpps.length > 0 ? (
                    <RadioGroup onChange={setSelectedRpps} value={selectedRpps}>
                      <Stack direction="column">
                        {availableRpps.map((member, index) => (
                          <Radio key={index} value={member.identificationNationalePP}>
                            {member.prenom} {member.nom} - {member.libelleCommune} ({member.identificationNationalePP})
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  ) : (
                    <p>Aucun professionnel de santé trouvé.</p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={onClose} isDisabled={!selectedRpps}>
                    Confirmer
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        ) : (
          <Button colorScheme="green" mt={4} onClick={handleDownload}>
            Télécharger le Document
          </Button>
        )}
      </StyledBox>
    </>
  );
}

export default SiretForm;

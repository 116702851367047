export const processDataJordan = (dataInpi, dataAkuiteo, dataAnnuaire, selectedRpps) => {

  const etablissement = dataInpi.formality;
  const personneMorale = etablissement.content.personneMorale;

  // Extraction du nom et des prénoms du titulaire
  const nomTitulaire = personneMorale.beneficiairesEffectifs[0].beneficiaire.descriptionPersonne.nom;
  const prenomsTitulaire = personneMorale.beneficiairesEffectifs[0].beneficiaire.descriptionPersonne.prenoms.join(" ");

  // Extraction de l'adresse
  const adresseEtablissement = personneMorale.adresseEntreprise.adresse;
  const adresse = `${adresseEtablissement.numVoie || ''} ${adresseEtablissement.typeVoie || ''} ${adresseEtablissement.voie}, ${adresseEtablissement.codePostal} ${adresseEtablissement.commune}`;

  // Extraction du SIREN et du SIRET
  const siren = etablissement.siren;
  const siret = personneMorale.etablissementPrincipal.descriptionEtablissement.siret;

  // Création de l'objet JSON avec les données extraites
  const extractedData = {
    "nom_titulaire": `${nomTitulaire} ${prenomsTitulaire}`,
    "adresse": adresse,
    "SIREN": siren,
    "SIRET": siret,
    "CIP": dataAkuiteo.CIPGERS,
    "FINESS": dataAkuiteo.IDNAT,
    "FINESSGEO": dataAkuiteo.FINESS,
    "mail": dataAkuiteo.E_mail,
    "telephone": dataAkuiteo.Téléphone,
    "AkiCode": dataAkuiteo.AkiCode,
    "RPPS": selectedRpps,
  };

  return extractedData;
};

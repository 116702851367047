import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import CreateActivityButton from '../components/ReportingApp/CreateActivityButton';
import CreateHotlineReportingButton from '../components/ReportingApp/CreateHotlineReportingButton';
import ManageTask from '../components/ReportingApp/ManageTask';
import ReturnButton from '../components/ReturnButton';
import { useAuth } from "../utils/auth/AuthContext.jsx";
import { useGetRequest } from '../utils/hooks/useGetRequest';

function Reporting() {
    const { userRole } = useAuth();
    const { isLoading: isUsersLoading, data: usersData, error: usersError } = useGetRequest('/api/activity/user');

    return (
        <>
            <Box p={5}>
                <ReturnButton />
                <CreateActivityButton users={usersData} />

                {userRole === "admin" && (
                    <CreateHotlineReportingButton users={usersData} />
                )}
                
                {userRole === "admin" && (
                    <ManageTask />
                )}
                <Link to="./statsactivity">
                    <Button ml={2} colorScheme="red">
                        Stats Activités
                    </Button>
                </Link>
                <Link to="./statshotline">
                    <Button ml={2} colorScheme="red">
                        Stats Hotline
                    </Button>
                </Link>
            </Box>
            <Outlet />
        </>
    );
}
export default Reporting;
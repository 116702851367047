import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, List, ListItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, Text, useToast, ModalCloseButton, Stack } from '@chakra-ui/react';
import { useGetRequest } from '../../utils/hooks/useGetRequest';
import { usePostRequest } from '../../utils/hooks/usePostRequest';

const TaskList = () => {
    const { isLoading, data: tasks, error } = useGetRequest('/api/task');
    const { postData, loading: taskLoading, error: taskError } = usePostRequest('/api/task');
    const [newTask, setNewTask] = useState({ role: '', tasks: '', categories: '' });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewTask({ ...newTask, [name]: value });
    };

    const handleSubmit = async () => {
        await postData({ ...newTask, tasks: newTask.tasks.split(','), categories: newTask.categories.split(',') });
        setNewTask({ role: '', tasks: '', categories: '' });
        onClose();
        toast({
            title: taskError ? 'Error adding task' : 'Task added',
            description: taskError ? taskError : 'Your task has been added successfully.',
            status: taskError ? 'error' : 'success',
            duration: 5000,
            isClosable: true,
        });
    };

    if (isLoading) return <Text>Loading...</Text>;
    if (error) return <Text>Error: {error.message}</Text>;

    return (
        <Box p={5}>
            <Button onClick={onOpen} colorScheme="blue" mt={5}>Add New Task</Button>
            <Modal isOpen={isOpen} size={"xl"} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add a New Task</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Role ( Si role déjà existant alors mettre le meme nom ) </FormLabel>
                                <Input name="role" value={newTask.role} onChange={handleChange} placeholder="Enter role" />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Tasks (séparer par des virgules si plusieurs : install addons,Prévisite)</FormLabel>
                                <Input name="tasks" value={newTask.tasks} onChange={handleChange} placeholder="Enter tasks, separated by commas" />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Categories (comma separated)</FormLabel>
                                <Input name="categories" value={newTask.categories} onChange={handleChange} placeholder="Enter categories, separated by commas" />
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit} isLoading={taskLoading}>
                            Save
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <List spacing={3} marginTop={8}>
                {tasks.map((task, index) => (
                    <ListItem key={index} borderWidth="1px" borderRadius="lg" padding={4}>
                        <Text fontWeight="bold">{task.role}</Text>
                        <Text>Tasks: {task.tasks.join(', ')}</Text>
                        <Text>Categories: {task.categories.join(', ')}</Text>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default TaskList;

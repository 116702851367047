import React, { useState } from 'react';
import {
  Box,
  VStack,
  Input,
  Button,
  Center,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { useGetRequest } from '../utils/hooks/useGetRequest'; // Assurez-vous que le chemin d'importation est correct

function SebApp() {
  // État pour le champ du code Akuitéo
  const [codeAkuiteo, setCodeAkuiteo] = useState('');
  // État pour les données de la pharmacie
  const [pharmacyData, setPharmacyData] = useState(null);
  // État pour l'erreur
  const [error, setError] = useState(null);
  // État pour le chargement
  const [isLoading, setIsLoading] = useState(false);

  // Gestionnaire pour le bouton de recherche
  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`localhost:3002/api/everys/client/${codeAkuiteo}`);
      const data = await response.json();
      setPharmacyData(data);
      setError(null);
    } catch (error) {
      setError(error.message);
      setPharmacyData(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Center h="100vh" w="100vw">
      <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
        <VStack spacing={4}>
          <FormControl id="code-akuiteo">
            <FormLabel>Code Akuitéo :</FormLabel>
            <Input
              type="text"
              value={codeAkuiteo}
              onChange={(e) => setCodeAkuiteo(e.target.value)}
              isDisabled={isLoading}
            />
            <Button mt={2} onClick={handleSearch} isLoading={isLoading}>
              Chercher
            </Button>
          </FormControl>
          <FormControl id="nom-officine" isReadOnly>
            <FormLabel>Nom de l'officine :</FormLabel>
            <Input type="text" value={pharmacyData?.nomOfficine || ''} isReadOnly />
          </FormControl>
          <FormControl id="nom-titulaire" isReadOnly>
            <FormLabel>Nom du Titulaire :</FormLabel>
            <Input type="text" value={pharmacyData?.nomTitulaire || ''} isReadOnly />
          </FormControl>
          <FormControl id="tel-pharma" isReadOnly>
            <FormLabel>Tél de la pharma :</FormLabel>
            <Input type="tel" value={pharmacyData?.telPharma || ''} isReadOnly />
          </FormControl>
          <FormControl id="code-postal-ville" isReadOnly>
            <FormLabel>Code postal + ville :</FormLabel>
            <Input type="text" value={pharmacyData?.codePostalVille || ''} isReadOnly />
          </FormControl>
          <FormControl id="email-client" isReadOnly>
            <FormLabel>E-mail du client :</FormLabel>
            <Input type="email" value={pharmacyData?.emailClient || ''} isReadOnly />
          </FormControl>
          <FormControl id="adeli" isReadOnly>
            <FormLabel>N° ADELI :</FormLabel>
            <Input type="text" value={pharmacyData?.numeroAdeli || ''} isReadOnly />
          </FormControl>
        </VStack>
        {error && <p>Erreur : {error}</p>}
      </Box>
    </Center>
  );
}

export default SebApp;

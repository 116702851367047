import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardContainer = styled.div`
  border-radius: 12px;
  padding: 15px;
  margin: 15px;
  background-color: #F0F4F8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
`;

const CardTitle = styled.h3`
  color: #1A202C;
  margin-bottom: 8px;
`;

const CardDescription = styled.p`
  color: #4A5568;
  font-size: 0.9em;
  margin-bottom: 12px;
`;

const StyledLink = styled(Link)`
  background-color: #3182CE;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  text-decoration: none; /* Enlève le soulignement par défaut des liens */
  display: block; /* S'assurer que le lien s'étend sur tout le bouton */
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2B6CB0;
  }
`;

const Card = ({ title, description, route }) => {
  return (
    <CardContainer>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      <StyledLink to={route}>Y Aller</StyledLink>
    </CardContainer>
  );
};

export default Card;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRequest } from '../../utils/hooks/useGetRequest';
import {
  Box,
  Text,
  VStack,
  Heading,
  Container,
  Spinner,
  Alert,
  AlertIcon,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Select
} from '@chakra-ui/react';

function UserDetails() {
  const { username } = useParams();

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

  const { isLoading, data: userStats, error } = useGetRequest(`/api/activity/stat/${username}`);

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  if (isLoading) {
    return (
      <Container centerContent>
        <Spinner />
      </Container>
    );
  }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        Il y a eu un problème lors du chargement des détails de l'utilisateur.
      </Alert>
    );
  }

  const filteredStats = {
    categories: userStats && userStats.categories ? userStats.categories.map(stat => {
      const filteredDates = stat.dates.filter(date => new Date(date).getMonth() === selectedMonth);
      return {
        ...stat,
        count: filteredDates.length, // Mise à jour du compteur basé sur le filtrage
        dates: filteredDates // Utilisation uniquement des dates filtrées
      };
    }).filter(stat => stat.count > 0) : [], // Filtre pour ne garder que les stats avec des activités dans le mois

    tasks: userStats && userStats.tasks ? userStats.tasks.map(stat => {
      const filteredDates = stat.dates.filter(date => new Date(date).getMonth() === selectedMonth);
      return {
        ...stat,
        count: filteredDates.length, // Mise à jour du compteur basé sur le filtrage
        dates: filteredDates // Utilisation uniquement des dates filtrées
      };
    }).filter(stat => stat.count > 0) : [] // Filtre pour ne garder que les stats avec des activités dans le mois
  };

  return (
    <Container maxW="container.md" >
      <Heading as="h2" size="lg" mb={5}>Dashboard de {username}</Heading>
      <Select placeholder="Choisir un mois" value={selectedMonth.toString()} onChange={handleMonthChange}>
        {months.map((month, index) => (
          <option key={index} value={index.toString()}>{month}</option>
        ))}
      </Select>
      <Tabs isFitted variant="enclosed" mt={5}>
        <TabList mb="1em">
          <Tab>Activités</Tab>
          <Tab>Statistiques</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={4} align="stretch">
              {filteredStats.categories.length > 0 && filteredStats.categories.map((category, index) => (
                <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <Text fontWeight="bold">{category.category}</Text>
                  <Text mt={2}>Nombre d'activités: {category.count}</Text>
                  <Text>Dates: {category.dates.map(date => new Date(date).toLocaleDateString()).join(', ')}</Text>
                </Box>
              ))}
              {filteredStats.tasks.length > 0 && filteredStats.tasks.map((task, index) => (
                <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <Text fontWeight="bold">{task.task}</Text>
                  <Text mt={2}>Nombre d'activités: {task.count}</Text>
                  <Text>Dates: {task.dates.map(date => new Date(date).toLocaleDateString()).join(', ')}</Text>
                </Box>
              ))}
              {(filteredStats.categories.length === 0 && filteredStats.tasks.length === 0) && (
                <Text>Aucune activité trouvée pour ce mois.</Text>
              )}
            </VStack>
          </TabPanel>

          <TabPanel>
            <VStack spacing={4} align="stretch">
              {filteredStats.categories.map((stat, index) => (
                <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <Text fontWeight="bold">{stat.category}</Text>
                  <Text mt={2}>Nombre d'activités: {stat.count}</Text>
                  <Text>Dates: {stat.dates.map(date => new Date(date).toLocaleDateString()).join(', ')}</Text>
                </Box>
              ))}
              {filteredStats.tasks.map((stat, index) => (
                <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <Text fontWeight="bold">{stat.task}</Text>
                  <Text mt={2}>Nombre d'activités: {stat.count}</Text>
                  <Text>Dates: {stat.dates.map(date => new Date(date).toLocaleDateString()).join(', ')}</Text>
                </Box>
              ))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}

export default UserDetails;

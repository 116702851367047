import React, { useState, useEffect } from 'react';
import {
  Box, Text, Spinner, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Tooltip, Flex, HStack, Menu,
  MenuButton, MenuList, MenuOptionGroup, MenuItemOption
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ViewIcon, ChevronDownIcon } from '@chakra-ui/icons';

import io from 'socket.io-client'

import CreateTicket from './CreateTicketButton';
import { useGetRequest } from '../../utils/hooks/useGetRequest';

import Cookies from 'js-cookie';

import formatDate from '../../utils/function/formateDate';

function TicketList() {
  const { data, error, isLoading } = useGetRequest('/api/support/ticket');
  const [tickets, setTickets] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      // Trier les tickets chargés par date de création, les plus récents en premier
      const sortedTickets = [...data].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setTickets(sortedTickets);
    }
  }, [data]);

  useEffect(() => {
    const token = Cookies.get('token');

    const socket = io(process.env.REACT_APP_URL_BACKEND, {
      path: '/socket.io',
      extraHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    socket.on('connect', () => {
      setSocketConnected(true);
    });

    socket.on('disconnect', () => {
      setSocketConnected(false);
    });

    socket.on('newTicket', (newTicket) => {
      setTickets((prevTickets) => {
        // Créer une nouvelle liste incluant le nouveau ticket
        const updatedTickets = [newTicket, ...prevTickets];
        // Trier cette liste pour s'assurer que les tickets sont bien ordonnés
        return updatedTickets.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      });
    });

    // Écouter les mises à jour de statut des tickets
    socket.on('ticketStatusUpdated', (updatedTicket) => {
      setTickets((prevTickets) => {
        return prevTickets.map((ticket) =>
          ticket._id === updatedTicket._id ? updatedTicket : ticket
        ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Tri à nouveau après mise à jour
      });
    });

    socket.on('ticketUpdated', (updatedTicket) => {
      setTickets((prevTickets) => {
        return prevTickets.map((ticket) =>
          ticket._id === updatedTicket._id ? updatedTicket : ticket
        ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      });
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('newTicket');
      socket.off('ticketStatusUpdated');
      socket.off('ticketUpdated');
      socket.close();
    };
  }, []);


  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <Text>Erreur lors du chargement des tickets.</Text>;
  }

  const handleCategoryChange = (value) => {
    setSelectedCategories(value);
  };

  const handleViewDetails = (readableId) => {
    navigate(`/support/ticket/${readableId}`);
  };

  const filteredTickets = tickets.filter(ticket => selectedCategories.length === 0 || selectedCategories.includes(ticket.category));

  return (
    <Box m={5} overflowX="auto">
      <Flex direction="column">
        <Flex justifyContent="flex-start" mb={2}>
          <Box borderRadius="full" width="10px" height="10px" bg={socketConnected ? 'green.500' : 'black'} mr={3} />
        </Flex>
        <Flex justifyContent="space-between" mb={2}>
          <HStack>
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm">
                Filtre
              </MenuButton>
              <MenuList>
                <MenuOptionGroup value={selectedCategories} title='Catégorie' type='checkbox' onChange={(e) => handleCategoryChange(e)}>
                  <MenuItemOption value='logiciel'>Logiciel</MenuItemOption>
                  <MenuItemOption value='technique'>Technique</MenuItemOption>
                  <MenuItemOption value='SAV'>SAV</MenuItemOption>
                  <MenuItemOption value='administratif'>Administratif</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </HStack>
          <HStack>
            <Button leftIcon={<ViewIcon />} colorScheme="teal" size="sm" onClick={() => navigate('/support/stat')}>
              Stat
            </Button>
            <CreateTicket />
          </HStack>
        </Flex>
      </Flex>
      <Table variant="simple" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>Titre</Th>
            <Th>Client</Th>
            <Th>Categorie</Th>
            <Th>Status</Th>
            <Th>Création</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredTickets && filteredTickets.length > 0 ? (
            filteredTickets.map((ticket) => (
              <Tr key={ticket._id}>
                <Td>{ticket.title}</Td>
                <Td>{ticket.client}</Td>
                <Td>{ticket.category}</Td>
                <Td>{ticket.status}</Td>
                <Td>{formatDate(ticket.createdAt)}</Td>
                <Td>
                  <Tooltip label="View details" hasArrow>
                    <IconButton
                      aria-label="View details"
                      icon={<ViewIcon />}
                      size="sm"
                      onClick={() => handleViewDetails(ticket.readableId)}
                      mr={2}
                    />
                  </Tooltip>
                  <Button size="sm" colorScheme="blue">Répondre</Button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={6}>Aucun ticket trouvé.</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
  
}  

export default TicketList;

import React from 'react';
import { Box, Button, FormControl, FormLabel, Input, Divider } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { usePostRequest } from '../utils/hooks/usePostRequest';
import { useCustomToast } from '../utils/hooks/toast';
import { useAuth } from '../utils/auth/AuthContext';

const ChangePasswordForm = () => {
  const { postData } = usePostRequest('/api/user/changepassword');
  const { showToast } = useCustomToast();
  const { logout } = useAuth();

  return (
    <Box my={8} mx="auto" p={5} maxWidth="500px" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Formik
        initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
        onSubmit={(values, actions) => {
          if (values.newPassword !== values.confirmPassword) {
            showToast('Les mots de passe ne correspondent pas.', 2000, true, 'error');
            actions.setSubmitting(false);
            return;
          }
          postData({
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
          })
            .then(() => {
              showToast('Mot de passe mis à jour avec succès.', 2000, false, 'success');
              logout();
              actions.setSubmitting(false);
            })
            .catch(error => {
              showToast(`${error}`, 2000, true, 'error');
              actions.setSubmitting(false);
            });
        }}
      >
        <Form>
          <Field name='oldPassword'>
            {({ field, form }) => (
              <FormControl mt={4} isInvalid={form.errors.oldPassword && form.touched.oldPassword}>
                <FormLabel>Ancien mot de passe</FormLabel>
                <Input {...field} type='password' />
              </FormControl>
            )}
          </Field>
          <Divider my={4} />
          <Field name='newPassword'>
            {({ field, form }) => (
              <FormControl mt={4} isInvalid={form.errors.newPassword && form.touched.newPassword}>
                <FormLabel>Nouveau mot de passe</FormLabel>
                <Input {...field} type='password' />
              </FormControl>
            )}
          </Field>
          <Divider my={4} />
          <Field name='confirmPassword'>
            {({ field, form }) => (
              <FormControl mt={4} isInvalid={form.errors.confirmPassword && form.touched.confirmPassword}>
                <FormLabel>Confirmer le mot de passe</FormLabel>
                <Input {...field} type='password' />
              </FormControl>
            )}
          </Field>
          <Button
            colorScheme='teal'
            width='full'
            mt={4}
            type='submit'
          >
            Changer le mot de passe
          </Button>
        </Form>
      </Formik>
    </Box>
  );
};

export default ChangePasswordForm;

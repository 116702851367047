import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Text, Button, useDisclosure, Grid, Input,
  Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  Select, Alert, AlertIcon, Spinner, FormControl, FormLabel, Textarea
} from '@chakra-ui/react';
import { useGetRequest } from '../../utils/hooks/useGetRequest';
import { usePutRequest } from '../../utils/hooks/usePutRequest';

function TicketDetail() {
  const { readableId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: ticket, isLoading, error: getError, refetch } = useGetRequest(`/api/support/ticket/${readableId}`);
  const { putData, loading: updating, error: updateError } = usePutRequest(`/api/support/ticket/${readableId}`);

  const [updateData, setUpdateData] = useState({ title: '', description: '', category: '', status: '' });

  useEffect(() => {
    if (ticket) {
      setUpdateData({ title: ticket.title, description: ticket.description, category: ticket.category, status: ticket.status });
    }
  }, [ticket]);

  const handleChange = e => {
    const { name, value } = e.target;
    setUpdateData(prevData => ({ ...prevData, [name]: value }));
  };

  const updateTicketDetails = async () => {
    try {
      await putData(updateData);
      refetch();
    } catch (error) {
      console.error("Erreur lors de la mise à jour du ticket", error);
    }
    onClose();
  };

  if (isLoading) return <Spinner center />;
  if (getError) return <Alert status="error"><AlertIcon />Erreur lors du chargement du ticket</Alert>;

  return (
    <Box p={5}>
  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
    <Box bg="gray.700" p={5} borderRadius="md" color="white">
      <Text fontSize="2xl" mb={2}>{updateData.title}</Text>
      <Text mb={4}>{updateData.description}</Text>
      <Text mb={2}>Catégorie: {updateData.category}</Text>
      <Text mb={2}>Statut: {updateData.status}</Text>
    </Box>
    <Box>
      <Button colorScheme="teal" onClick={onOpen}>Modifier le ticket</Button>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Modifier le ticket</DrawerHeader>
          <DrawerBody>
            <FormControl>
              <FormLabel>Titre</FormLabel>
              <Input name="title" value={updateData.title} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea name="description" value={updateData.description} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Catégorie</FormLabel>
              <Select name="category" value={updateData.category} onChange={handleChange}>
                <option value='logiciel'>Logiciel</option>
                <option value='technique'>Technique</option>
                <option value='SAV'>SAV</option>
                <option value='administratif'>Administratif</option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Statut</FormLabel>
              <Select name="status" value={updateData.status} onChange={handleChange}>
                <option value='new'>Nouveau</option>
                <option value='in_progress'>En cours</option>
                <option value='closed'>Fermé</option>
              </Select>
            </FormControl>
            <Button isLoading={updating} colorScheme="teal" mt={4} onClick={updateTicketDetails}>
              Mettre à jour
            </Button>
            {updateError && <Alert status="error"><AlertIcon />Erreur lors de la mise à jour du ticket</Alert>}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  </Grid>
</Box>

  );
}

export default TicketDetail;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

const ReturnButton = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Button onClick={handleGoBack} colorScheme="yellow">
            Retour
        </Button>
    );
};

export default ReturnButton;
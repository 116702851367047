import React, { useState } from 'react';
import { useGetRequest } from '../utils/hooks/useGetRequest';
import { usePostRequest } from '../utils/hooks/usePostRequest';
import {
  Box,
  Button,
  Heading,
  Select,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';

const Admin = () => {
  const { isLoading: isUsersLoading, data: usersData, error: usersError } = useGetRequest('/api/user/');
  const { isLoading: isVehiclesLoading, data: vehiclesData, error: vehiclesError } = useGetRequest('/api/quartix/vehicles');
  const { postData } = usePostRequest('/api/user/linkVehicle');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const toast = useToast();

  const handleLinkVehicle = async () => {
    if (!selectedUser || !selectedVehicle) {
      toast({
        title: "Error",
        description: "Please select both a user and a vehicle",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await postData({ userId: selectedUser, vehicleId: selectedVehicle });
      toast({
        title: "Success",
        description: response.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setSelectedUser('');
      setSelectedVehicle('');
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || 'An error occurred',
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isUsersLoading || isVehiclesLoading) return <Text>Loading...</Text>;
  if (usersError) return <Text>Error loading users: {usersError.message}</Text>;
  if (vehiclesError) return <Text>Error loading vehicles: {vehiclesError.message}</Text>;

  const vehiclesList = vehiclesData ? Object.keys(vehiclesData).map(key => vehiclesData[key]) : [];

  return (
    <Box p={5}>
      <Heading as="h1" mb={6}>Admin</Heading>
      <Stack spacing={4}>
        <Select placeholder="Select User" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
          {usersData && usersData.map((user) => (
            <option key={user._id} value={user._id}>{user.nom} {user.prenom}</option>
          ))}
        </Select>
        <Select placeholder="Select Vehicle" value={selectedVehicle} onChange={(e) => setSelectedVehicle(e.target.value)}>
          {vehiclesList.map((vehicle) => (
            <option key={vehicle.VehicleId} value={vehicle.VehicleId}>
              {vehicle.RegistrationNumber} - {vehicle.Description}
            </option>
          ))}
        </Select>
        <Button colorScheme="teal" onClick={handleLinkVehicle}>Link Vehicle to User</Button>
      </Stack>
    </Box>
  );
};

export default Admin;

import React from 'react';
import styled from 'styled-components';
import Card from '../components/Card';

const HomeContainer = styled.div`
  padding: 20px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Title = styled.h1`
  width: 100%;
`;

const Home = () => {
  return (
    <HomeContainer>
      <Title>Bienvenue sur ProxyOutils</Title>
      {/* <Card 
        title="Reporting activité" 
        description="Rapport d'activité des techniciens, formateurs et commerciaux."
        route="/reporting" 
      /> */}
      <Card 
        title="Jordan Generator V0.1" 
        description="Générateur de document pour préparer les visites des formateurs"
        route="/genere/jordan" 
      />
      {/* <Card 
        title="Seb application" 
        description="App [en stand-by]"
        route="/genere/seb" 
      /> */}
      <Card 
        title="Elsa QR code generator" 
        description="Génére QR code pour elsa"
        route="/genere/elsa" 
      />

      <Card
      title=""
      description=""
      route="/vehicle-stats"

      />

    </HomeContainer>
  );
};

export default Home;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './utils/auth/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Home from './pages/Home';
import Genere from './pages/Genere';
import Login from './pages/Login';
import ChangePassword from './pages/ChangePassword';
import ConsoleEverys from './pages/ConsoleEverys';
import Support from './pages/Support';
import Reporting from './pages/Reporting';
import Admin from './pages/Admin';
import VehicleStats from './pages/VehicleStats';
import SebApp from './components/SebApp';
import GenereJordan from './components/GenereJordan';
import GenereElsa from './components/GenereElsa';
import TicketDetails from './components/TicketSupport/TicketDetails';
import TicketStat from './components/TicketSupport/TicketStat';
import TicketList from './components/TicketSupport/TicketList';
import UserList from './components/ReportingApp/UserList';
import UserDetails from './components/ReportingApp/UserDetails';
import TaskList from './components/ReportingApp/TaskList';
import StatsActivity from './components/ReportingApp/StatsActivity';
import StatsHotline from './components/ReportingApp/StatsHotline';
import Header from './layout/Header';
import Error from './layout/Error';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          {/* Connexion Route */}
          <Route path="login" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="change-password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />

          {/* Acces Home */}
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />

          <Route path="/everysconsole" element={<PrivateRoute><ConsoleEverys /></PrivateRoute>} />

          {/* Route for support */}
          <Route path="/support" element={<PrivateRoute><Support /></PrivateRoute>} >
            <Route path="" element={<PrivateRoute><TicketList /></PrivateRoute>} />
            <Route path="ticket/:readableId" element={<PrivateRoute><TicketDetails /></PrivateRoute>} />
            <Route path="stat" element={<PrivateRoute><TicketStat /></PrivateRoute>} />
          </Route>

          {/* Route for genere */}
          <Route path="/genere" element={<PrivateRoute><Genere /></PrivateRoute>}>
            <Route path="jordan" element={<PrivateRoute><GenereJordan /></PrivateRoute>} />
            <Route path="seb" element={<PrivateRoute><SebApp /></PrivateRoute>} />
            <Route path="elsa" element={<PrivateRoute><GenereElsa /></PrivateRoute>} />
          </Route>

          {/* Route for reporting */}
          <Route path="/reporting" element={<PrivateRoute><Reporting /></PrivateRoute>}>
            <Route path="" element={<PrivateRoute><UserList /></PrivateRoute>} />
            <Route path=":username" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
            <Route path="task" element={<PrivateRoute><TaskList /></PrivateRoute>} />
            <Route path="statsactivity" element={<PrivateRoute><StatsActivity /></PrivateRoute>} />
            <Route path="statshotline" element={<PrivateRoute><StatsHotline /></PrivateRoute>} />
          </Route>


          {/* Route for vehicle stats */}
          <Route path="/vehicle-stats" element={<PrivateRoute><VehicleStats /></PrivateRoute>} />

          {/* Route for admin */}

          <Route path="/admin" element={<PrivateRoute adminOnly={true}><Admin /></PrivateRoute>}>
              
          </Route>

          {/* Route for 404 */}
          <Route path="*" element={<Error />} />

        </Routes>
      </Router>
    </AuthProvider>
  );
}
export default App;

import React, { useEffect, useState } from 'react';
import {
  Box, Text, Spinner, Table, Thead, Tbody, Tr, Th, Td, Select, Button
} from '@chakra-ui/react';
import { useGetRequest } from '../../utils/hooks/useGetRequest';

function Stats() {
  const [role, setRole] = useState('technicien');
  const { data: tasksData, error: tasksError, isLoading: tasksLoading } = useGetRequest('/api/task');
  const { data: statsData, error: statsError, isLoading: statsLoading } = useGetRequest(`/api/activity/stats/${role}`);

  const [tableData, setTableData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    new Date()
      .toLocaleString('fr-FR', { month: 'long' })
      .replace(/^./, match => match.toUpperCase())
  );

  useEffect(() => {
    if (statsData && tasksData) {
      const transformedData = [];

      statsData.forEach(userStats => {
        const username = Object.keys(userStats)[0];
        const userMonths = userStats[username].months;

        if (userMonths[selectedMonth]) {
          const rowData = { username, role, ...userMonths[selectedMonth] };
          transformedData.push(rowData);
        }
      });

      setTableData(transformedData);
    }
  }, [statsData, tasksData, selectedMonth, role]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleRoleChange = (newRole) => {
    setRole(newRole);
  };

  if (tasksLoading || statsLoading) {
    return <Spinner />;
  }

  if (tasksError || statsError) {
    return <Text>Erreur lors du chargement des données.</Text>;
  }

  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  const currentRoleTasks = tasksData.find(task => task.role === role);

  return (
    <Box m={5} overflowX="auto">
      <Box mb={4}>
      <Button mr={4} onClick={() => handleRoleChange('technicien')}>Technicien</Button>
      <Button onClick={() => handleRoleChange('formateur')}>Formateur</Button>
      </Box>
      <Select placeholder="Sélectionnez un mois" value={selectedMonth} onChange={handleMonthChange} mb={4}>
        {months.map(month => (
          <option key={month} value={month}>{month}</option>
        ))}
      </Select>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Role</Th>
            {currentRoleTasks && currentRoleTasks.tasks.map(task => (
              <Th key={task}>{task}</Th>
            ))}
            {currentRoleTasks && currentRoleTasks.categories.map(category => (
              <Th key={category}>{category}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((data, index) => (
            <Tr key={index}>
              <Td>{data.username}</Td>
              <Td>{data.role}</Td>
              {currentRoleTasks.tasks.map(task => (
                <Td key={task}>{data[task] || 0}</Td>
              ))}
              {currentRoleTasks.categories.map(category => (
                <Td key={category}>{data[category] || 0}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

export default Stats;

import React, { useState, useEffect } from 'react';
import { useGetRequest } from '../utils/hooks/useGetRequest';
import {
  Box,
  Heading,
  Stack,
  Text,
  Select,
  Spinner,
  Alert,
  AlertIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';

const VehicleStats = () => {
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { isLoading: isVehiclesLoading, data: vehiclesData, error: vehiclesError } = useGetRequest('/api/quartix/vehicles');
  const { data: statsData, isLoading: isStatsLoading, error: statsError, refetch } = useGetRequest(
    `/api/quartix/vehicles/stats?vehicleId=${selectedVehicle}&startDay=${startDate}&endDay=${endDate}`,
    false // do not fetch on initial render
  );

  useEffect(() => {
    if (selectedVehicle && startDate && endDate) {
      refetch();
    }
  }, [selectedVehicle, startDate, endDate, refetch]);

  if (isVehiclesLoading) return <Spinner />;
  if (vehiclesError) return <Alert status="error"><AlertIcon />{vehiclesError.message}</Alert>;

  const vehiclesList = vehiclesData ? Object.keys(vehiclesData).map(key => vehiclesData[key]) : [];
  const filteredTrips = statsData?.Data.filter(trip => trip.TravelTime > 0.0833); // 5 minutes in hours

  return (
    <Box p={5}>
      <Heading as="h1" mb={6}>Vehicle Stats</Heading>
      <Stack spacing={4} mb={6}>
        <Select placeholder="Select Vehicle" value={selectedVehicle} onChange={(e) => setSelectedVehicle(e.target.value)}>
          {vehiclesList.map((vehicle) => (
            <option key={vehicle.VehicleId} value={vehicle.VehicleId}>
              {vehicle.RegistrationNumber} - {vehicle.Description}
            </option>
          ))}
        </Select>
        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
      </Stack>
      {isStatsLoading ? <Spinner /> : statsError ? (
        <Alert status="error">
          <AlertIcon />
          {statsError.message}
        </Alert>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Start Date</Th>
              <Th>End Date</Th>
              <Th>Distance (km)</Th>
              <Th>Average Speed (km/h)</Th>
              <Th>Max Speed (km/h)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredTrips?.map((trip, index) => (
              <Tr key={index}>
                <Td>{new Date(trip.StartDateTime).toLocaleString()}</Td>
                <Td>{new Date(trip.EndDateTime).toLocaleString()}</Td>
                <Td>{trip.Distance.toFixed(2)}</Td>
                <Td>{trip.AvgSpeed.toFixed(2)}</Td>
                <Td>{trip.MaxSpeed}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default VehicleStats;

import React from 'react';
import { Box, Text, Button, Flex, VStack } from '@chakra-ui/react';
import { useGetRequest } from '../../utils/hooks/useGetRequest';

const TicketStat = () => {
    const { data: userStats, isLoading, error } = useGetRequest('/api/support/stats');

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <VStack p={4} spacing={4} align="stretch">
            <h1>Ticket Stats</h1>
            {userStats && userStats.map(userStat => (
                <Box p={3} shadow="md" borderWidth="1px" key={userStat.username} maxW="sm" borderRadius="lg" overflow="hidden">
                    <Flex direction="column" align="center">
                        <Box p="2">
                            <Text fontSize="md">Utilisateur : {userStat.username}</Text>
                        </Box>
                        <Box>
                            <Text fontSize="sm">Tickets Créés: {userStat.totalOpened}</Text>
                            <Text fontSize="sm">Tickets Fermés: {userStat.totalClosed}</Text>
                        </Box>
                        <Button size="sm" mt={2} onClick={() => {/* logic to handle detail view */}}>Détails</Button>
                    </Flex>
                </Box>
            ))}
        </VStack>
    );
}

export default TicketStat;

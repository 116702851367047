import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Select,
    useToast,
    useDisclosure,
    Tag,
    TagLabel,
    TagCloseButton,
    Flex,
    Input
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { usePostRequest } from '../../utils/hooks/usePostRequest';
import { useGetRequest } from '../../utils/hooks/useGetRequest'; // Import de useGetRequest pour récupérer les tâches
import { useAuth } from '../../utils/auth/AuthContext';

const CreateActivityModal = ({ users }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [task, setTask] = useState('');
    const [codeGRP, setCodeGRP] = useState('');
    const [assignedTo, setAssignedTo] = useState('');
    const [assignedRole, setAssignedRole] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const toast = useToast();
    const { postData, error, loading } = usePostRequest('/api/activity');
    const { isLoading: isTasksLoading, data: rolesData, error: tasksError } = useGetRequest('/api/task'); // Requête pour récupérer les tâches
    const { userRole } = useAuth();

    useEffect(() => {
        setAssignedRole(userRole);
    }, [userRole]);

    useEffect(() => {
        if (assignedTo && users) {
            const selectedUser = Object.entries(users).flatMap(([role, users]) =>
                users.find(user => user.userId === assignedTo) ? { role, ...users } : null
            ).find(user => user);
            setAssignedRole(selectedUser ? selectedUser.role : '');
        }
    }, [assignedTo, users]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await postData({ task, assignedTo, categories, date: selectedDate });

            toast({
                title: 'Activité créée',
                description: "Votre activité a été créée avec succès.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            setTask('');
            setAssignedTo('');
            onClose();
        } catch (err) {
            toast({
                title: 'Erreur lors de la création de l\'activité',
                description: error?.message || "Une erreur s'est produite",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const userTasks = rolesData?.find(roleData => roleData.role === assignedRole)?.tasks || [];
    const userCategories = rolesData?.find(roleData => roleData.role === assignedRole)?.categories || [];

    const toggleCategory = (category) => {
        if (categories.includes(category)) {
            setCategories(categories.filter(cat => cat !== category));
        } else {
            setCategories([...categories, category]);
        }
    };

    return (
        <>
            <Button ml={5} colorScheme='teal' onClick={onOpen} isLoading={loading} mr={2}>
                Créer une activité
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Créer une activité</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            {userRole === 'admin' && (
                                <FormControl mt={4}>
                                    <FormLabel>Assigné à</FormLabel>
                                    <Select placeholder='Utilisateur assigné' value={assignedTo} onChange={(e) => setAssignedTo(e.target.value)}>
                                        {users && Object.entries(users).map(([role, users]) => (
                                            users.map(user => (
                                                <option key={user.userId} value={user.userId}>{`${user.nom} ${user.prenom} (${role})`}</option>
                                            ))
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            <FormControl mt={4}>
                                <FormLabel>CodeGRP ou autre</FormLabel>
                                <Input placeholder='Info Client (GRP ou autre)' value={codeGRP} onChange={(e) => setCodeGRP(e.target.value)} />
                            </FormControl>

                            <FormLabel mt={4}>Date</FormLabel >
                            <DatePicker
                                selected={selectedDate}
                                onChange={date => setSelectedDate(date)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Sélectionner une date"
                            />

                            <FormControl isRequired mt={4}>
                                <FormLabel>Tâche</FormLabel>
                                <Select placeholder='Sélectionner une tâche' value={task} onChange={(e) => setTask(e.target.value)}>
                                    {userTasks.map((task, index) => (
                                        <option key={index} value={task}>{task}</option>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl isRequired mt={4}>
                                <FormLabel>Catégories</FormLabel>
                                <Flex flexWrap="wrap">
                                    {userCategories.map((category, index) => (
                                        <Tag
                                            key={index}
                                            mr={2}
                                            mb={2}
                                            variant='solid'
                                            colorScheme={categories.includes(category) ? 'teal' : 'gray'}
                                            cursor="pointer"
                                            onClick={() => toggleCategory(category)}
                                        >
                                            <TagLabel>{category}</TagLabel>
                                        </Tag>
                                    ))}
                                </Flex>
                            </FormControl>

                            {categories.length > 0 && (
                                <Flex mt={2}>
                                    {categories.map((selectedCategory, index) => (
                                        <Tag key={index} mr={2} mb={2} variant='solid' colorScheme='teal'>
                                            <TagLabel>{selectedCategory}</TagLabel>
                                            <TagCloseButton onClick={() => setCategories(categories.filter(category => category !== selectedCategory))} />
                                        </Tag>
                                    ))}
                                </Flex>
                            )}
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} type="submit" isLoading={loading}>
                                Soumettre
                            </Button>
                            <Button onClick={onClose}>Fermer</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateActivityModal;

import { useState, useCallback } from 'react';
import Cookies from 'js-cookie';

export function useDeleteRequest() {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteRequest = useCallback(async (url) => {
        if (!url) return;
        setLoading(true);
        setError(null);

        try {
            const token = Cookies.get('token');
            if (!token) {
                throw new Error("Token not found");
            }
            const response = await fetch(`${process.env.REACT_APP_URL_BACKEND}${url}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

        } catch (err) {
            console.error('Erreur lors de la suppression:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, []);

    return { isLoading, error, deleteRequest };
}

import React from 'react';
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const ManageTask = () => {
    return (
        <Link to="/reporting/task">
            <Button>
                Manage Task
            </Button>
        </Link>

    );
}

export default ManageTask;

import React, { useState, useRef } from 'react';
import { Input, Button, Box, useToast, List, ListItem } from '@chakra-ui/react';
import QrCodeDisplay from './QrCodeDisplay';

function GenereElsa() {
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState([]);
  const [showQrCode, setShowQrCode] = useState(false);
  const [counter, setCounter] = useState(0);
  const toast = useToast();
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddClick = () => {
    if (inputValue) {
      if (data.includes(inputValue)) {
        toast({
          title: "Étiquette en double",
          description: "Cette étiquette existe déjà.",
          status: "warning",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => inputRef.current.focus(),
        });
        setInputValue('');
      } else {
        setData([...data, inputValue]);
        setInputValue('');
        setShowQrCode(false);
        setCounter(counter + 1);
        inputRef.current.focus();
      }
    }
  };

  const handleRemoveLast = () => {
    setData(data.slice(0, -1));
    setCounter(Math.max(counter - 1, 0));
    inputRef.current.focus();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddClick();
      e.preventDefault();
    }
  };

  const generateQrCode = () => {
    setShowQrCode(true);
    setCounter(0);
  };

  const handleReset = () => {
    setData([]);
    setShowQrCode(false);
    setCounter(0);
    inputRef.current.focus();
  };

  return (
    <>
      <Box p={4}>
        <Input
          placeholder="Entrez une série de chiffres"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          mb={2}
          ref={inputRef}
        />
        <Button onClick={handleAddClick} colorScheme="blue" mr={2}>
          Ajouter
        </Button>
        <Button onClick={generateQrCode} colorScheme="teal">
          Générer QR Code
        </Button>
        <Button onClick={handleReset} colorScheme="red" ml={2}>
          Réinitialiser
        </Button>
        <Button onClick={handleRemoveLast} colorScheme="orange" ml={2}>
          Retirer Dernière Étiquette
        </Button>
        <p>Nombre d'étiquettes scannées : {counter}</p>
      </Box>
      <Box p={4}>
        {showQrCode && <QrCodeDisplay data={data} />}
      </Box>
      <Box p={4}>
        <List spacing={3}>
          {data.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </List>
      </Box>
    </>
  );
}

export default GenereElsa;

import React, { useRef, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // Assurez-vous que c'est le bon import
import { Button } from '@chakra-ui/react';

const QrCodeDisplay = ({ data }) => {
  const qrRef = useRef(null);

  useEffect(() => {
    if (qrRef.current) {
      const canvas = qrRef.current.querySelector('canvas');
      const image = canvas.toDataURL("image/png");
      const downloadLink = qrRef.current.querySelector('a#downloadLink');
      downloadLink.href = image;
    }
  }, [data]);

  // Filtrer et concaténer les données, en retirant "062231" si présent
  const filteredData = data.map(code => code.replace('062231', '')).join('');
  const qrData = `qrc:${data.length},type:062231,list:${filteredData}`;

  return (
    <div ref={qrRef}>
      <QRCodeCanvas value={qrData} size={256} />
      <br />
      <a id="downloadLink" download="qrcode.png">
        <Button colorScheme="teal">Télécharger QR Code</Button>
      </a>
    </div>
  );
};

export default QrCodeDisplay;

import React from 'react'
import UploadEverysConsole from '../components/UploadEverysConsole'

function ConsoleEverys(){
    return(
    <div>
        <UploadEverysConsole></UploadEverysConsole>
    </div>
    )
}

export default ConsoleEverys
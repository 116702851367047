import React, { useState } from 'react';
import { Button, useToast, Center } from '@chakra-ui/react';
import axios from 'axios';

function UploadEveryConsole() {
  const [selectedFile, setSelectedFile] = useState(null);
  const toast = useToast();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type !== "text/csv") {
      toast({
        title: 'Type de fichier non supporté',
        description: 'Veuillez sélectionner un fichier CSV.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      toast({
        title: 'Aucun fichier sélectionné',
        description: 'Veuillez sélectionner un fichier CSV',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/everys/majeverys`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast({
        title: 'Succès',
        description: `Fichier téléchargé avec succès : ${response.data.message}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur lors du téléchargement',
        description: `Erreur lors du téléchargement du fichier : ${error.response?.data?.message || error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Center mb={4}>
      <input
        id="file-upload"
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Button colorScheme="blue" onClick={() => document.getElementById('file-upload').click()}>
        Sélectionner un fichier CSV
      </Button>
      {selectedFile && <Button colorScheme="green" onClick={handleSubmit} ml={3}>Envoyer</Button>}
    </Center>
  );
}

export default UploadEveryConsole;

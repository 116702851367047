import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Select,
    useToast
} from '@chakra-ui/react';
import { usePostRequest } from '../../utils/hooks/usePostRequest';

const CreateHotlineReporting = ({ users }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toast = useToast();
    const { postData, error, loading } = usePostRequest('/api/hotlinereporting');
    const [formData, setFormData] = useState({
        date: '',
        user: '',
        morningCalls: '',
        afternoonCalls: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        try {
            // Envoi des données du formulaire
            await postData(formData);
    
            // Affichage d'un message de succès
            toast({
                title: 'Rapport de hotline créé',
                description: 'Le rapport de hotline a été créé avec succès.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
    
            // Réinitialisation du formulaire
            setFormData({
                date: '',
                user: '',
                morningCalls: '',
                afternoonCalls: '',
            });
    
            // Fermeture de la modale
            setIsOpen(false);
        } catch (error) {
            // Affichage d'un message d'erreur en cas d'échec de la requête
            toast({
                title: 'Erreur lors de la création du rapport de hotline',
                description: error.message || 'Une erreur s\'est produite.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };
    

    return (
        <>
            <Button colorScheme='teal' onClick={() => setIsOpen(true)} mr={2}>Créer un rapport de hotline</Button>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Créer un rapport de hotline</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>Date</FormLabel>
                            <Input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Utilisateur</FormLabel>
                            <Select
                                name="user"
                                value={formData.user}
                                onChange={handleChange}
                                placeholder="Sélectionnez un utilisateur"
                            >
                                {users && Object.keys(users).map((role) => (
                                    users[role].map((user) => (
                                        <option key={user.userId} value={user.userId}>
                                            {user.nom} {user.prenom}
                                        </option>
                                    ))
                                ))}
                            </Select>
                        </FormControl>


                        <FormControl mb={4}>
                            <FormLabel>Appels clos le matin</FormLabel>
                            <Input
                                type="number"
                                name="morningCalls"
                                value={formData.morningCalls}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Appels clos l'après-midi</FormLabel>
                            <Input
                                type="number"
                                name="afternoonCalls"
                                value={formData.afternoonCalls}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Enregistrer
                        </Button>
                        <Button onClick={() => setIsOpen(false)}>Annuler</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateHotlineReporting;

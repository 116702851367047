import React, { useState, useEffect } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Input, useToast, Menu, MenuButton, MenuList, MenuItemOption, MenuOptionGroup, Flex, IconButton } from '@chakra-ui/react';
import { EditIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useGetRequest } from '../../utils/hooks/useGetRequest';
import { useDeleteRequest } from '../../utils/hooks/useDeleteRequest';
import DeleteConfirmation from '../DeleteConfirmation';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import { fr } from 'date-fns/locale';

function HotlineReporting() {
  const startOfThisWeek = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd', { locale: fr });
  const endOfThisWeek = format(endOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd', { locale: fr });
  const [stats, setStats] = useState([]);
  const [startDate, setStartDate] = useState(startOfThisWeek);
  const [endDate, setEndDate] = useState(endOfThisWeek);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const toast = useToast();

  const { data, isLoading, error } = useGetRequest(`/api/hotlinereporting?startDate=${startDate}&endDate=${endDate}`);
  const { isLoading: isDeleting, error: deleteError, deleteRequest } = useDeleteRequest();

  useEffect(() => {
    if (data) {
      setStats(data);
    }
  }, [data]);

  useEffect(() => {
    setStartDate(startOfThisWeek);
    setEndDate(endOfThisWeek);
  }, []);


  const deleteEntry = async (id) => {
    await deleteRequest(`/api/hotlinereporting/${id}`);
    if (!deleteError) {
      setStats(currentStats => currentStats.filter(item => item._id !== id));
      toast({
        title: 'Suppression réussie',
        description: 'L\'entrée a été correctement supprimée.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Erreur de suppression',
        description: `Une erreur est survenue: ${deleteError}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUserSelect = (value) => {
    setSelectedUsers(value);
  };

  const filteredData = stats.filter(item => selectedUsers.length === 0 || selectedUsers.includes(item.username))
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const users = [...new Set(stats.map(item => item.username))];

  return (
    <Box p={5}>
      <Flex gap="20px" marginBottom="20px" alignItems="center">
        <Input
          type="date"
          value={startDate}
          size="sm"
          width="auto"
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Date de début"
        />
        <Input
          type="date"
          value={endDate}
          size="sm"
          width="auto"
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="Date de fin"
        />
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Filtre
          </MenuButton>
          <MenuList minWidth='240px'>
            <MenuOptionGroup
              value={selectedUsers}
              title='Utilisateurs'
              type='checkbox'
              onChange={handleUserSelect}
            >
              {users.map(user => (
                <MenuItemOption key={user} value={user}>
                  {user}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Flex>

      {isLoading ? (
        <p>Chargement...</p>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Utilisateur</Th>
              <Th>Appels Matinaux</Th>
              <Th>Appels Après-midi</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((item) => (
              <Tr key={item._id}>
                <Td>{new Date(item.date).toLocaleDateString('fr-FR')}</Td>
                <Td>{item.username}</Td>
                <Td>{item.morningCalls}</Td>
                <Td>{item.afternoonCalls}</Td>
                <Td>
                  <IconButton mr={2} colorScheme="orange" icon={<EditIcon />} aria-label="Edit entry" />
                  <DeleteConfirmation onConfirm={() => deleteEntry(item._id)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

export default HotlineReporting;

import React from 'react';
import { useGetRequest } from '../../utils/hooks/useGetRequest';
import { Box, Text, Link, Heading, Container, Flex, Divider, VStack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

function UserList() {
  const { isLoading, data, error } = useGetRequest('/api/activity/user');
  
  if (isLoading) return <Box color="white">Chargement...</Box>;
  if (error) return <Box color="red.500">Une erreur est survenue.</Box>;

  return (
    <Container maxW="container.xl" color="white">
      <Flex direction="row" wrap="wrap" justify-content="flex-start">
        {Object.keys(data || {}).map(role => (
          <Box key={role} p={3} w="30%" minW="300px" rounded="md" shadow="md" mb={4} bgColor="gray.800" textAlign="center">
            <Heading size="md" mb={2}>{role}</Heading>
            <Divider mb={4}/>
            <VStack divider={<Divider borderColor="gray.600" />} spacing={4}>
              {data[role].map(user => (
                <Link as={RouterLink} to={`/reporting/${user.username}`} key={user.username} style={{ textDecoration: 'none' }}>
                  <Box p={2} _hover={{ bg: "gray.700" }}>
                    <Text fontSize="sm" fontWeight="bold">{user.nom} {user.prenom}</Text>
                  </Box>
                </Link>
              ))}
            </VStack>
          </Box>
        ))}
      </Flex>
    </Container>
  );
}

export default UserList;

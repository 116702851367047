import React from 'react';
import styled from 'styled-components';
import { Flex, Button, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/auth/AuthContext.jsx";
import { useCustomToast } from "../utils/hooks/toast.js";
import ToggleColorMode from "../components/ToggleColorMode";
import casque from "../assets/headphone.svg";
import logout1 from "./../assets/logout.svg";
import admin from "./../assets/admin.svg";

const NavbarContainer = styled(Flex)`
  background-color: #15202B;
  padding: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// Ajustements supplémentaires peuvent ne pas être nécessaires
const StyledLink = styled(Link)`
  margin-right: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const SupportButton = styled(Button)`
  background: transparent;
  padding: 0;
  min-width: auto;
  margin-right: 1rem;
`;

const Navbar = () => {
  const { userRole, logout, isLoggedIn } = useAuth();
  const { showToast } = useCustomToast();
  const navigate = useNavigate();

  const disconnection = async () => {
    logout();
    navigate('/login');
    showToast('Vous avez été deconnecté !', 2000, true, 'success');
  };

  return (
    <NavbarContainer>
      <Flex align="center">
        <StyledLink to="/">
          <Button colorScheme="blue" variant="solid" size="md">Accueil</Button>
        </StyledLink>
        <StyledLink to="/support">
          <SupportButton>
            <Image src={casque} alt="Support" boxSize={["16px", "24px"]} />
          </SupportButton>
        </StyledLink>
      </Flex>
      <Flex align="center">


        {/* Afficher le bouton Admin si l'utilisateur est un administrateur */}
        {userRole === "admin" && (
          <Button
            as={Link}
            to="/admin"
            size={["sm", "lg"]}
            marginLeft="2"
            variant="ghost"
            color="white"
          >
            <img src={admin} alt="Admin" />
          </Button>
        )}

        {isLoggedIn && (
          <Button onClick={disconnection} size="sm" variant="ghost" color="white">
            <img src={logout1} alt="Logout" />
          </Button>
        )}
        <ToggleColorMode />
      </Flex>
    </NavbarContainer>
  );
}

export default Navbar;

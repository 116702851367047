import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Select,
    useToast,
    useDisclosure
} from '@chakra-ui/react';
import { usePostRequest } from '../../utils/hooks/usePostRequest';

const CreateTicketModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [title, setTitle] = useState('');
    const [client, setClient] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const toast = useToast();
    const { postData, error, loading } = usePostRequest('/api/support/ticket');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await postData({ title, client, description, category });

            toast({
                title: 'Ticket créé',
                description: "Votre ticket a été créé avec succès.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            setTitle('');
            setClient('');
            setDescription('');
            setCategory('');
            onClose();
        } catch (err) {
            toast({
                title: 'Erreur lors de la création du ticket',
                description: error?.message || "Une erreur s'est produite",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Button ml={5} colorScheme='teal' variant='outline' onClick={onOpen} isLoading={loading}>
                Créer un ticket
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Créer un ticket</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                        <FormControl isRequired>
                                <FormLabel>Titre</FormLabel>
                                <Input placeholder='Titre du ticket' value={title} onChange={(e) => setTitle(e.target.value)} />
                            </FormControl>

                            <FormControl mt={4} isRequired>
                                <FormLabel>Client</FormLabel>
                                <Input placeholder='Client' value={client} onChange={(e) => setClient(e.target.value)} />
                            </FormControl>

                            <FormControl mt={4} isRequired>
                                <FormLabel>Description</FormLabel>
                                <Input placeholder='Description du problème' value={description} onChange={(e) => setDescription(e.target.value)} />
                            </FormControl>

                            <FormControl mt={4} isRequired>
                                <FormLabel>Catégorie</FormLabel>
                                <Select placeholder='Sélectionner une catégorie' value={category} onChange={(e) => setCategory(e.target.value)}>
                                    <option value='technique'>Technique</option>
                                    <option value='logiciel'>Logiciel</option>
                                    <option value='SAV'>SAV</option>
                                    <option value='administratif'>Administratif</option>
                                </Select>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} type="submit" isLoading={loading}>
                                Soumettre
                            </Button>
                            <Button variant='ghost' onClick={onClose}>Fermer</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateTicketModal;
